import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import config from "../../../../config";
import icons, { iconsAlts } from "../../../../resources/icons";
import images, { imagesAlts } from "../../../../resources/images";
import classes from "./BusinessMenu.module.css";

const BusinessMenu = ({ className, menu, entityName }) => {
  const [isMenuExpanded, setMenuExpanded] = useState(false);
  const [isMenuExpandable, setMenuExpandable] = useState(false);
  const ref = useRef(null);

  const isIL = config.currentCountry == "IL";

  useEffect(() => {
    try {
      const currentHeight = ref.current.clientHeight;
      const minHeight = convertRemToPixels(40);
      setMenuExpandable(currentHeight >= minHeight);
    } catch { }
  });

  function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  return menu && menu.categories && menu.categories.length > 0 &&(
    <div className={ `${classes.mainContainer} ${className}` } id="menu_container">
      <div className={ classes.divider }></div>
      <h3 className={ classes.mainHeader }>{ t("menu") }</h3>
      <div
        id="containerDiv"
        ref={ ref }
        className={
          isMenuExpanded
            ? classes.menuContainerExpanded
            : menu.categories && menu.categories.length > 2
              ? classes.menuDoubleColumn
              : classes.menuGrid
        }
      >
        { menu.categories &&
          menu.categories.map((category) => (
            <div className={ classes.categoryContainer }>
              <h4 className={ classes.categoryHeader }>{ category.name }</h4>
              { category.dishes.map((dish) => (
                <div className={ classes.dishContainer }>
                  <div className={ classes.dishHeaderContainer }>
                    <h5 className={ classes.dishName }>{ dish.name }</h5>
                    { dish.prices.length > 0 && (
                      <p className={ classes.dishPrice }>
                        { !isIL ? t("currency") : "" }
                        { dish.prices.map((p) => p.price).join("/") }
                        { isIL ? t("currency") : "" }
                      </p>
                    ) }
                  </div>
                  <div className={ classes.dishDescriptionContainer }>
                    <p className={ classes.dishDescription }>{ dish.description }</p>
                    { dish.vote_counter > 0 && (
                      <div className={ classes.ratingContainer }>
                        <img src={ icons.thumbsUpEmpty } alt={ iconsAlts.thumbsUpEmpty } />
                        <p className={ classes.dishRating }>{ dish.vote_counter }</p>
                      </div>
                    ) }
                  </div>
                </div>
              )) }
            </div>
          )) }
        <div className={ classes.bottomShade } />
      </div>

      { isMenuExpandable && (
        <button
          type="button"
          className={ classes.expandButton }
          onClick={ () => {
            setMenuExpanded((expanded) => !expanded);
            const myDiv = document.getElementById("containerDiv");
            myDiv.scrollTop = 0;
          } }
        >
          <p>{ isMenuExpanded ? t("close_menu") : t("expand_menu") }</p>
          <img
            className={ isMenuExpanded ? classes.expandButtonIconExpanded : classes.expandButtonIcon }
            src={ icons.greyArrowDown }
            alt={ iconsAlts.greyArrowDown }
          />
        </button>
      ) }
    </div>
  ) 
};

export default BusinessMenu;
