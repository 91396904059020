import { t } from "i18next";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import PrimaryButton from "../../components/Common/PrimaryButton/PrimaryButton";
import { s3 } from "../../resources/config";
import icons, { iconsAlts } from "../../resources/icons";
import images, { imagesAlts } from "../../resources/images";
import { getCurrentCountry, getCurrentLocale } from "../../utils/utilFunctions";
import classes from "./GalleryModal.module.css";

const GalleryModal = ({ show, onHide, selectedIndex, coverImages }) => {
  const getSelectedImageByLocale = () => {
    const locale = getCurrentLocale();
    if (locale === "he") {
      return coverImages.length - selectedIndex - 1;
    } else {
      return selectedIndex;
    }
  };

  const getCoverImagesByLocale = () => {
    const locale = getCurrentLocale();
    if (locale === "he") {
      return [...coverImages].reverse();
    } else {
      return coverImages;
    }
  };

  const arrowPrevHandler = (clickHandler, _, label) => {
    return (
      <button
        type="button"
        className={ `${ classes.previousButton }` }
        onClick={ () => {
          clickHandler();
        } }
      >
        <img src={ images.galleryPreviousButton } alt={ imagesAlts.galleryPreviousButton } />
      </button>
    );
  };

  const arrowNextHandler = (clickHandler, _, label) => {
    return (
      <button
        type="button"
        className={ `${ classes.nextButton }` }
        onClick={ () => {
          clickHandler();
        } }
      >
        <img src={ images.galleryNextButton } alt={ imagesAlts.galleryNextButton } />
      </button>
    );
  };

  return (
    <Modal
      show={ show }
      contentClassName={ classes.modalContainer }
      backdropClassName={ classes.backdrop }
      onHide={ onHide }
      size={ "xl" }
      centered
      dialogClassName={ classes.modalClass }
    >
      <img
        className={ getCurrentLocale() === "he" ? classes.exitButtonIL : classes.exitButton }
        src={ icons.closeXWhite }
        onClick={ onHide }
        alt={ iconsAlts.closeXWhite }
      />
      <Carousel
        className={ classes.carousel }
        autoPlay={ false }
        showArrows={ true }
        showStatus={ false }
        showThumbs={ false }
        showIndicators={ true }
        dynamicHeight={ false }
        useKeyboardArrows={ true }
        infiniteLoop={ true }
        renderArrowPrev={ arrowPrevHandler }
        renderArrowNext={ arrowNextHandler }
        selectedItem={ getSelectedImageByLocale() }
      >
        { getCoverImagesByLocale().map((image, index) => (
          <div key={ index } className={ `${ classes.mainContainer } }` }>
            <div style={ { position: 'relative' } }>
              <img className={ classes.image } src={ image.url } alt="gallery image" />

              {/* AI Image Label */ }
              { image.is_illustration && (

                <div
                  style={ {
                    position: 'absolute',
                    bottom: '50px',
                    right: '10px',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    color: 'white',
                    padding: '5px 10px',
                    fontSize: '12px',
                    borderRadius: '200px',
                    zIndex: 10, // Ensure the label is above the image
                  } }
                >
                  AI Image
                </div>
              ) }
            </div>
          </div>
        )) }
      </Carousel>
    </Modal>
  );
};

export default GalleryModal;
